$path: '../';
$path-css: $path+'images/css/';
$font-path: $path+'fonts/';
$fa-font-path: $path+"fonts/font-awesome";

// ---------------------------- grid
$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1366px;

$screen-xs-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;
$screen-lg-max: ($screen-xl - 1) !default;

$grid-breakpoints: (
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
);

$container-max-widths: (
  sm: 540px,
  md: 687px,
  lg: 960px,
  xl: 1192px
);

$grid-gutter-width: 22px;
$gw: $grid-gutter-width;

$grid-columns: 12;

$font-1: 'MuseoCyrl';
$font-2: 'Cambria';

// ---------------------------- colors
$color-bg: #212121;
$color-text: #262626;
$color-bg-l: #2A2A2A;
$color-dimmed: #EFEFEF;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:	  #E73F2F;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;
$gray:    #d6d6d6;

$link-hover-color: $red;

// ---------------------------- sizes
$header-height-xs: 68px;
$header-height-xl: 86px;

$section-padding: 60px;
$section-padding-md: 80px;
$section-padding-xl: 95px;


// ---------------------------- others
$radius: 5px;