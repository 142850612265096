// Футер книзу
.wrap-site {
	min-height: 100%;
	min-width: 320px;
	position: relative;
	width: 100%;
	overflow: hidden;
	background: $color-bg;
	color: #fff;

	display: flex;
	flex-flow: column;
	display: none;
}
.main{
	position: relative;
	background: $color-bg-l;
	padding-top: $header-height-xs;

	@include media-breakpoint-up(xl) {
		padding-top: $header-height-xl;
	}
	.main-page &{
		padding-top: 0;
	}
	.flexbox &{
		flex: 1 0 auto;
	}
	.nav-showed &{
		filter: blur(4px);
	}
}
// .Футер книзу
.nav-showed {
	overflow: hidden !important;
}
.wrap{
	margin-right: auto;
	margin-left: auto;
	padding-right: $gw;
	padding-left: $gw;
	width: 100%;
	max-width: $screen-xxl;
	@include media-breakpoint-up(md) {
		padding-left: ($gw*2);
		padding-right: ($gw*2);
	}
	@include media-breakpoint-up(xxl) {
		padding-right: ($gw*3);
		padding-left: ($gw*3);
	}
}

.event-page {
	.wrap {
		max-width: $screen-xxl;
	}
}
