@charset "utf-8";
.breadcrumbs{
	margin: 0 0 40px;
	&--title-offset{
		@include media-breakpoint-up(md) {
			margin-top: 25px;
		}
	}
	&--pos-contacts{
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 55px;
			right: 0;
			margin: 0 47px 0 0;
		}
		@include media-breakpoint-up(lg) {
			margin: 0 93px 0 0;
		}
	}
	&--pos-spaces{
		position: relative;
		z-index: 1;
	}
	@media print {
		display: none;
	}
	&__item{
		display: inline-block;
		vertical-align: middle;
		list-style: none;
		color: #fff;
		font-size: 13px;
		a{
			@extend %link-clicker;
			display: inline-block;
			transition: color 0.2s;
			color: inherit;
			&:hover{
				color: $red;
			}
		}
		span,
		a{
			&:before{
				content: '//';
				margin: 0 0.4em 0 0.2em;
				display: inline-block;
			}
		}
		span{}
		&:first-child{
			span,
			a{
				&:before{
					display: none;
				}
			}
		}
	}
}