%link-clicker{
	@include link-clicker;
}
%clearfix {
	*zoom: 1;
	&:after {
		content: '';
		display: table;
		line-height: 0;
		clear: both;
	}
}

%btn-style{
	@extend %link-clicker;
	display: inline-block;
	border: none;
	border-radius: $radius;
	font-size: 12px;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
}

%center-responsive-img{
	position: relative;
	&:before{
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
	}
	img{
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
	}
}