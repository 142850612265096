input,
textarea,
button {
	box-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

a,
input,
select,
textarea,
button {
	&:focus {
		outline: none;
	}
}

ul,
ol,
li,
dl,
dt,
dd,
p {
	margin: 0;
	padding: 0;
}

time {
	display: block;
}

a {
	text-decoration: none;
	color: inherit;
	transition: color 0.2s;
}

b,
strong {
	font-weight: $bold;
}

hr,
.hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: 30px 0;
	line-height: 1.1;
	font-family: $font-2;
	@include media-breakpoint-up(md) {
		margin: 40px 0;
	}
}

h1, h2,
.h2, .h1 {
	font-weight: normal;
	font-size: 30px;
	line-height: (36/30);
	@include media-breakpoint-up(md) {
		line-height: (59/50);
		font-size: 50px;
	}
}

h3,
.h3 {
	font-weight: normal;
	font-size: 24px;
	text-transform: uppercase;
}

h4,
.h4 {
	margin: 0;
	margin-bottom: 20px;
	font-weight: normal;
	font-size: 28px;
}

.content-img {
	display: inline-block;
	position: relative;
	margin: 0 40px 44px 0;
	@include media-breakpoint-up(md) {
		margin: 0 70px 54px 0;
	}
	&--left{
		@include media-breakpoint-up(md) {
			float: left;
		}
	}
	&--right{
		@include media-breakpoint-up(md) {
			float: right;
			margin: 0 44px 54px 20px;
		}
	}

	img {
		position: relative;
		max-width: 100%;
	}
	&:before {
		content: '';
		position: absolute;
		right: -24px;
		bottom: -24px;
		top: 36px;
		width: 60%;
		border: 3px solid #fff;
		@include media-breakpoint-up(md) {
			right: -44px;
			bottom: -44px;
			top: 68px;
		}
	}
	&:after {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#27233A, 0.22);
	}
}

.styled-table {
	width: 100%;
	thead {
		tr {
			background: $color-bg;
			padding: 0;
		}
	}
	tbody {
		tr {
			&:nth-child(odd) {
				background: $color-bg;
				@include media-breakpoint-up(lg) {
					background: none;
				}
			}
			&:nth-child(even) {
				@include media-breakpoint-up(lg) {
					background: $color-bg;
				}
			}
		}
	}
	tr {
		display: block;
		padding: 10px 0;
		@include media-breakpoint-up(lg) {
			display: table-row;
		}
	}

	th {
		display: none;
	}

	td {
		position: relative;
		display: block;
		padding: 10px 10px 10px 40%;
		&:before {
			position: absolute;
			content: attr(data-th)": ";
			display: block;
			font-weight: bolder;
			top: 0.6em;
			left: 10px;
			width: 40%;
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	th,
	td {
		@include media-breakpoint-up(lg) {
			padding: 17px 20px 23px;
			display: table-cell;
		}
	}
}

.content {
	&--dark-bg {
		background-color: $color-bg;
	}
	img, picture {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	p {
		margin-bottom: 1.5em;
		img{
			float: right;
			margin-bottom: .5em;
			margin-left: .5em;
		}
	}
	a {
		@extend %link-clicker;
		display: inline-block;
		color: $red;
		>i {
			color: #fff;
			margin-left: 0.5em;
		}
	}

	blockquote {
		padding: 5px 20px;
		border-left: 3px solid $red;
		font-style: italic;
		margin: 1.5em 0;
	}

	ul {
		li {
			list-style: none;
			position: relative;
			padding-left: 30px;
			margin-bottom: 10px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: em(10, 15);
				left: 0;
				width: 12px;
				height: 3px;
				background: $red;
			}
			&.round {
				&::before {
					top: em(10, 20);
					width: 7px;
					height: 7px;
					border-radius: 50%;
				}
			}
		}
	}
}