.arcticmodal-overlay,
.arcticmodal-container{
	z-index: $zindex-modal-backdrop;
}

.arcticmodal-container_i{
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
}
.arcticmodal-container_i2{
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 0;
	@include media-breakpoint-up(md) {
		padding: 15px 40px;
	}
	.arcticmodal-img &{
		padding-top: 0;
		padding-bottom: 0;
	}
}
.arcticmodal-loading{
	display: inline-block;
}

.popup{
	position: relative;
	background: $color-bg;
	color: #fff;
	text-align: left;
	margin: 40px auto;
	max-width: 660px;
	padding: 60px $gw;
	@include media-breakpoint-up(md) {
		padding: 70px 95px 80px;
	}
	&--img{
		position: relative;
		max-width: 100%;
		display: inline-block;
		width: auto;
		vertical-align:bottom;
		padding: 40px 10px 20px;
		@include media-breakpoint-up(sm) {
			padding: 0 0 20px;
		}
	}
	&--big {
		@include media-breakpoint-up(xxl) {
			padding: 60px 60px 70px;
			max-width: 1245px;
		}
	}
	&--rent {
		padding-bottom: 0;
		background-color: $color-bg-l;
		.input-style,
		.select-style {
			background-color: $color-bg-l;
		}
	}

	&__close{
		position: absolute;
		z-index: 1;
		top: -20px;
		right: 50%;
		margin-right: -20px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
		transition: transform 0.1s, color 0.1s;
		color: #fff;
		background: $red;
		@include media-breakpoint-up(md) {
			right: -20px;
			margin: 0;
		}
		&:active{
			background: darken($red, 5%);
		}
	}
	&__title{
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 700;
		text-align: center;
		margin: 0 0 25px;
	}
	&__sub-title {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 30px;
		font-size: 24px;
		line-height: 32px;
	}
	&__wrapper {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__textarea,
	&__input,
	&__select{
		margin-top: 20px;
	}

	&__conditions{
		margin: 30px 0;
	}
	&__btn-wrap{
		margin-top: 40px;
		text-align: center;
	}

	&__img-wrap{
		@extend .fa;
		position: relative;
		min-width: 240px;
		min-height: 150px;
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0; margin: auto;
			width: 1em;
			height: 1em;
			font-size: 28px;
			opacity: 0;
			transition: all 0.2s;
		}
		.popup--loading &{
			&:before{
				@extend .fa-spin;
				content: $fa-var-spinner;
				opacity: 1;
				z-index: 1;
				transition: all 0.2s 0.5s;
			}
			img{
				opacity: 0.3;
				transition: all 0.2s 0.2s;
			}
		}
		img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
			cursor: pointer;
			transition: all 0.2s;
		}
	}

	&__btn-next,
	&__btn-prev{
		position: absolute;
		bottom: 17px;

		@extend %link-clicker;
		font-size: 0;
		background: none;
		border: 2px solid;
		height: 30px;
		width: 33px;
		color: #fff;
		cursor: pointer;
		transition: color 0.2s;
		&:before{
			font-size: 18px;
			@extend .fa;
		}
		&:hover{
			color: $red;
		}
		@include media-breakpoint-up(sm) {
			border: 3px solid;
			height: 48px;
			width: 48px;
		}
	}
	&__btn-next{
		@extend .fa-long-arrow-right;
		left: 50%;
		margin-left: 10px;
	}
	&__btn-prev{
		@extend .fa-long-arrow-left;
		right: 50%;
		margin-right: 10px;
	}
	&__img-caption{
		text-align: center;
		margin-top: 15px;
	}
	&__hide-toggle {
		position: relative;
		margin: 0;
		padding: 23px 0;
		font-size: 16px;
		line-height: 21px;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			padding: 16px 0 20px;
			font-size: 24px;
			line-height: 32px;
		}
		&:hover {
			color: $red;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: -22px;
			right: -22px;
			height: 100%;
			background-color: $color-bg;
			@include media-breakpoint-up(md) {
				left: -95px;
				right: -95px;
			}
		}
		span {
			position: relative;
			z-index: 1;
			transition: all 0.2s;
		}

		i {
			position: absolute;
			top: 50%;
			right: 0;
			z-index: 1;
			width: 18px;
			height: 18px;
			transform: translateY(-50%);
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				width: 100%;
				height: 2px;
				background-color: currentColor;
				transform: translateY(-50%);
				transition: all 0.2s;
			}
			&::after {
				transform: translateY(-50%) rotate(90deg);
			}
		}

		&.active {
			i::after {
				transform: translateY(-50%) rotate(0);
			}
			& ~ .popup__hide-block {
				display: block;
			}
		}
	}
	&__hide-block {
		display: none;
		padding: 40px 0 50px;
		@include media-breakpoint-up(md) {
			padding-top: 45px;
		}
	}
	&__rent-cost {
		font-size: 24px;
		line-height: 32px;
		span {
			font-size: 28px;
			line-height: 37px;
			color: $red;
			white-space: nowrap;
		}
	}

	&--img {
		iframe {
			height: 200px;
			border: none;
			@include media-breakpoint-up(md) {
				width: 650px;
				height: 350px;
			}
		}
	}
}