.header{
	position: relative;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	background: $color-bg;
	// transition: height 0.2s, background 0.2s;
	padding: 8px 0;
	@include media-breakpoint-up(xl) {
		padding: 0;
	}

	&.headroom--top{
		position: absolute;
	}
	&.headroom--not-top{
		position: fixed;
		z-index: $zindex-fixed;
	}

	&__row{
		align-items: center;
	}
	&__logo-col{
		display: flex;
		align-items: center;
	}
	&__logo-col,
	&__col{
		position: static;
		@include media-breakpoint-up(md) {
			position: relative;
		}
	}
	&__logo{
		position: relative;
		width: 74px;
		transition: width 0.2s;
		@include media-breakpoint-up(md) {
			margin-right: 33px;
		}
		@include media-breakpoint-up(xl) {
			margin-right: 0;
			width: 91px;
		}

		.headroom--not-top &{
			width: 58px;
			@include media-breakpoint-up(md) {
				width: 74px;
			}
		}
	}

	&__logo-img{
		width: 100%;
	}

	&__tel{
		display: block;
		transition: all 0.2s;
		font-weight: $bold;
		@include media-breakpoint-up(md) {
			color: $red;
		}

		&:hover{
			text-decoration: none;
		}

		.headroom--not-top &{
			top: 14px;
		}
		>i {
			display: block;
			color: #fff;
			font-size: 25px;
			position: relative;
			top: 0.05em;
			@include media-breakpoint-up(md) {
				display: inline-block;
				margin-right: 0.8em;
				font-size: 16px;
			}
		}
	}
	&__btn-wrap{
		position: fixed;
		z-index: $zindex-sticky;
		bottom: 0;
		left: 0;
		right: 0;
		height: 58px;
		background: $color-text;

		@include media-breakpoint-up(md) {
			margin-right: 38px;
			position: static;
			height: auto;
			background: none;
		}
		@include media-breakpoint-up(xl) {
			margin-left: 32px;
			margin-right: 0;
		}

		.nav-showed &{
			filter: blur(4px);
			@include media-breakpoint-up(md) {
				filter: none;
			}
		}
	}

	&--event {
		.header {
			&__logo {
				@include media-breakpoint-up(md) {
					margin-right: 0;
				}
			}
		}
	}
}

.nav{
	position: fixed;
	z-index: $zindex-popover;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

	background: rgba($color-bg-l, 0.95);
	@include media-breakpoint-up(xl) {
		display: block;
		position: static;
		opacity: 1;
		visibility: visible;
		background: none;
		text-align: left;
	}
	.nav-showed &{
		visibility: visible;
		opacity: 1;
	}
	&__list{
		@include media-breakpoint-up(xl) {
			margin-left: -22px;
			display: flex;
		}
	}
	&__item{
		position: relative;
		list-style: none;
		margin-top: 40px;
		@include media-breakpoint-up(xl) {
			margin-left: 20px;
			margin-top: 0;
			&--event {
				margin-left: 15px;
			}
		}
		
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			margin-top: 6px;
			width: 0;
			height: 3px;
			background: $red;

			transition: width 0.2s;
			margin-left: auto;
			margin-right: auto;
			right: 0;
			left: 0;
			@include media-breakpoint-up(xl) {
				right: auto;
				margin-top: -3px;
			}
		}
		&:hover{
			&:after{
				width: 35px;
			}
		}
		&.active{
			&:after{
				width: 35px;
				@include media-breakpoint-up(xl) {
					width: 100%;
				}
			}
		}
	}
	&__link{
		@extend %link-clicker;
		display: inline-block;
		text-transform: uppercase;
		font-weight: 500;
		transition: height 0.2s;
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
			height: 86px;
		}
		&:hover{
			text-decoration: none;
			color: inherit;
		}

		.headroom--not-top &{
			@include media-breakpoint-up(xl) {
				height: 65px;
			}
		}
	}
}

%icon-lines{
	display: block;
	width: 22px;
	height: 3px;
	background-color: #fff;
	border-radius: 4px;
}
.menu-trigger{
	display: block;
	position: relative;
	z-index: $zindex-popover;
	width: 22px;
	height: 42px;
	border: none;
	background: none;
	border: none;
	padding: 0;
	text-transform: uppercase;
	cursor: pointer;
	transition: background 0.2s;
	&:after{
		content: '';
		display: block;
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
	}
	&--pos-header{
		transition: all 0.2s;
		@include media-breakpoint-up(md) {
			position: relative;
			margin-right: -7px;
		}
	}
	&__icon{
		@extend %icon-lines;
		display: block;
		position: absolute;
		top: 50%;
		margin: -1px auto 0;
		left: 0;
		right: 0;

		transition:
		transform 0.2s
		,background 0.2s
		,width 0.2s
		;
		&:before, &:after{
			@extend %icon-lines;
			position: absolute;
			content: '';

			transition:
			top 0.2s 0.2s
			,background 0.2s
			,transform 0.2s
			,bottom 0.2s 0.2s
			,width 0.2s
			;
		}
		&:before{
			top: -9px;
		}
		&:after{
			bottom: -9px;
		}
		.nav-showed &{
			transform: rotate(45deg);
			transition:
			transform 0.2s 0.2s
			,background 0.2s
			,width 0.2s 0.2s
			;
			&:before, &:after{
				transition:
				top 0.2s
				,background 0.2s
				,bottom 0.2s
				,transform 0.2s 0.2s
				,width 0.2s 0.2s
				;
			}

			&:before{
				top: 0;
			}
			&:after{
				bottom: 0;
				transform: rotate(90deg);
			}
		}
	}
}