.footer{
	padding: 37px 0 95px;
	@include media-breakpoint-up(md) {
		padding: 20px 0 30px;
	}
	@include media-breakpoint-up(xl) {
		padding: 43px 0 40px;
	}
	.nav-showed &{
		filter: blur(4px);
	}
	// &__row{
	// 	min-height: 177px;
	// }
	&__logo{
		width: 123px;
		@include media-breakpoint-up(md) {
			width: 91px;
		}
		@include media-breakpoint-up(xl) {
			width: 95px;
		}
	}
	&__contacts{
		width: 100%;
		text-align: center;
		margin: 25px 0 10px;
		@include media-breakpoint-up(md) {
			margin: 0;
			text-align: left;
		}
		@include media-breakpoint-up(xl) {
			text-align: left;
		}
	}
	&__tel{
		display: inline-block;
		vertical-align: middle;
		color: $red;
		font-size: 15px;
		font-weight: $bold;
		margin-bottom: 15px;
		>i {
			margin-right: 0.5em;
			color: #fff;
			font-size: 1rem;
		}
		@include media-breakpoint-up(md) {
			margin-top: 20px;
			margin-bottom: 30px;
		}
		@include media-breakpoint-up(xl) {
			margin-top: 0;
			margin-bottom: 5px;
		}
	}
	&__address {
		font-size: 13px;
	}
	&__developer{
		font-size: 13px;
		@include media-breakpoint-up(xl) {
			margin-top: 20px;
		}
		>a{
			text-decoration: underline;
		}
	}
}

.f-nav{
	margin-bottom: 12px;
	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}
	&__list{
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: right;
			margin-left: -28px;
		}
	}
	&__item{
		position: relative;
		list-style: none;
		margin-top: 15px;
		@include media-breakpoint-up(md) {
			display: inline-block;
			margin-left: 22px;
			margin-top: 0;
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			margin-top: 6px;
			width: 0;
			height: 3px;
			background: $red;

			transition: width 0.2s;

			margin-left: auto;
			margin-right: auto;
			right: 0;
			left: 0;
			@include media-breakpoint-up(md) {
				right: auto;
			}
		}
		&:hover{
			&:after{
				width: 35px;
			}
		}
		&.active{
			&:after{
				width: 35px;
			}
		}
	}
	&__link{
		@extend %link-clicker;
		display: inline-block;
		text-transform: uppercase;
		font-weight: 500;
		&:hover{
			text-decoration: none;
			color: inherit;
		}
	}
}