.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
	a{
		text-decoration: underline;
		color: #6b75d7;
		&:hover{
			text-decoration: none;
		}
	}
}
.b-soc{
	font-size: 19px;
	&--pos-header{
		display: inline-block;
		font-size: 14px;
	}
	&--pos-footer{
		margin-bottom: 15px;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
	.contacts__text &{
		margin-top: 20px;
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
	&__inner{
		margin: -5px 0 0 em(-12, 19);
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.b-soc--pos-header &{
			margin: -5px 0 0 0;
		}
	}
	&__item{
		display: inline-block;
		vertical-align: middle;
		margin-left: $gw;
		margin: 5px 0 0 em(9, 19);
		line-height: 1;
		fill: #fff;
		padding: 5px 5px;

		.b-soc--pos-header &{
			margin: 5px 0 0 0;
		}

		>img{
			max-width: 1em;
		}

		svg {
			display: block;
			transition: fill .2s;
		}

		&:hover{
			fill: $red;
		}
	}
	a{
		>i {
			margin: 0;
			display: block;
		}
		&:hover{
			text-decoration: none;
			>i {
				color: inherit;
			}
		}
	}
}
.btn{
	@extend %link-clicker;
	display: inline-block;
	background: none;
	border: 1px solid transparent;
	padding: 9px 30px;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	min-width: 140px;

	text-align: center;
	font-weight: 500;
	letter-spacing: 0.04em;
	user-select: none;
	transition: background 0.1s, color 0.1s;
	@include media-breakpoint-up(md) {
		width: auto;
		min-width: 140px;
		max-width: none;
	}
	&--red{
		background: $red;
		color: #fff;
		&:hover{
			text-decoration: none;
			color: #fff;
			background: lighten($red, 3%);
		}
		&:active{
			background: darken($red, 3%);
		}
	}
	&--hollow {
		background: transparent;
		border: none;
		box-shadow: inset 0 0 0 2px #fff;
		&:hover,
		&:active {
			text-decoration: none;
			color: #fff;
			background: transparent;
		}
	}
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}
.input-style,
.select-style {
	display: block;
	width: 100%;
	height: 48px;
	border: 3px solid #fff;
	padding: 0 20px;
	background: $color-bg;
	color: #fff;
	transition: all 0.2s;
	&:focus{
		border-color: $red;
	}
	&--date {
		padding-right: 40px;
		background-image: url("../images/svg/calendar.svg");
		background-repeat: no-repeat;
		background-position: 96% 50%;
		@include media-breakpoint-up(md) {
			background-position: 95% 50%;
		}
	}
	@include placeholderStyle {
		color: #fff;
	}
	&--light {
		color: $color-bg;
		background: #fff;
		@include placeholderStyle {
			color: $color-bg;
		}
	}
}
textarea.input-style{
	height: auto;
	min-height: 130px;
	max-height: 250px;
	resize: vertical;
	padding-top: 10px;
	padding-bottom: 10px;
}

img[data-object-fit="contain"] { object-fit: contain }

img[data-object-fit="cover"] { object-fit: cover }

img[data-object-fit="scale-down"] { object-fit: scale-down }

img[data-object-fit="none"] { object-fit: none }

.fit-img{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	>img{
		opacity: 0;
	}

	&--cover{ background-size: cover; }
	&--contain{ background-size: contain; }
}

.verticalize{ @include verticalize; }

.visuallyhidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px; width: 1px;
	margin: -1px; padding: 0; border: 0;
}

.responsive-img{
	position: relative;
	&:before{
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: cp(1,1)
	}
	img{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.section{
	padding: $section-padding 0;
	@include media-breakpoint-up(md) {
		padding: $section-padding-md 0;
	}
	@include media-breakpoint-up(xl) {
		padding: $section-padding-xl 0;
	}
}

.title-no-offset{
	margin: 0;
}
.title-underline{
	&:after {
		content: '';
		display: block;
		width: 35px;
		height: 3px;
		background: $red;
		margin: 25px 0 30px;
		@include media-breakpoint-up(md) {
			margin: 30px 0 40px;
		}
	}
	&--center{
		text-align: center;
		&:after{
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.ui-checkbox,
.ui-radiobox{
	position: relative;
	display: inline-block;
	padding: 5px 0 5px 2.2em;
	cursor: pointer;
	font-size: 15px;
	&__input{}
	&__icon{
		position: absolute;
		top: em(6,15);
		left: 0;
		display: inline-block;
		vertical-align: top;
		width: em(21,15);
		height: em(21,15);
		border: 2px solid $red;
		text-align: center;
		margin-right: 0.3em;
		&:before{
			opacity: 0;
			font-size: 0.85em;
			color: #fff;
		}
		.ui-checkbox__input:checked +&,
		.ui-radiobox__input:checked +&{
			&:before{
				opacity: 1;
			}
		}
	}

	.ui-radiobox {
		&__icon{
			border-radius: 50%;
			&::before {
				content: "";
				position: absolute;
				top: calc(50% - 3.5px);
				left: calc(50% - 3.5px);
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: #fff;
			}
		}
	}
	&__text{
		display: inline-block;
		vertical-align: top;
	}
}

.form-group {
	margin-top: 40px;
	margin-bottom: 40px;
	padding-bottom: 27px;
	&--underline {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: -$gw;
			right: -$gw;
			height: 1px;
			background-color: #454545;
			@include media-breakpoint-up(md) {
				left: -95px;
				right: -95px;
			}
		}
	}
}

.form-group-title {
	margin-bottom: 23px;
}

.avatar-load {
	position: relative;
	display: block;
	margin: 0 auto 30px;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 3px solid #fff;
	background-color: #9A9A9A;
	overflow: hidden;
	cursor: pointer;
	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 34px;
		height: 5px;
		background-color: #fff;
		transform: translate(-50%, -50%);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	input {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

.range-style {
	padding-top: 12px;
	padding-bottom: 40px;
	.noUi-target {
		height: 3px;
		background: #fff;
		border: none;
		box-shadow: none;
	}
	.noUi-handle {
		top: -12px;
		width: 28px;
		height: 28px;
		background-color: #fff;
		border-radius: 50%;
		border: 2px solid $color-bg;
		box-shadow: none;
		// box-shadow: inset 0 0 0 2px $color-bg;
		cursor: pointer;
		outline: none;
		&::before {
			display: none;
		}
		&::after {
			top: 50%;
			left: 50%;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: $red;
			transform: translate(-50%, -50%);
		}
	}
	.noUi-tooltip {
		color: #fff;
		border: none;
		background: transparent;
	}
	.noUi-horizontal .noUi-tooltip {
		top: 120%;
	}
}

.light-block {
	color: #262626;
	background-color: #efefef;
}