@import "mixins/rem.mixin";
@import "mixins/tooltips.mixin";
@import "mixins/clicker.mixin";
@import "mixins/verticalize.mixin";
@import "mixins/checkbox.mixin";
@import "mixins/truncate-text.mixin";
@import "mixins/placeholder.mixin";

@function cp($target, $container) {
	@return ($target / $container) * 100%;
}

@function em($i, $j) {
	@return ($i / $j) * 1em;
}

@function vw($i, $j) {
	@return ($i / ($j / 100)) * 1vw;
}

@mixin grayscale_element($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter:#{"grayscale(#{$value})"};
}