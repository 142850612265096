.staff{
	background: $color-bg;
}

.staff-slider{
	margin-bottom: 70px;
	@include media-breakpoint-up(md) {
		margin: 0 -35px 50px;
	}
	@include media-breakpoint-up(lg) {
		margin: 0 0 50px;
	}
	&__slide{}
	&__img{
		margin-bottom: 25px;
		&:before{
			padding-bottom: cp(357,377);
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			background: rgba(#27233A, 0.22);
			transition: all 0.2s;
			@include media-breakpoint-up(md) {
				background: rgba(#27233A, 0.5);
			}
			@include media-breakpoint-up(lg) {
				background: rgba(#27233A, 0.22);
			}
			.slick-current &{
				background: rgba(#27233A, 0.22);
			}
		}
		&:hover{
			&:after{
				opacity: 0;
			}
		}
	}
	@at-root{
		%corners-style {
			content: '';
			position: absolute;
			z-index: 1;
			display: block;
			width: 24px;
			height: 24px;
			border: solid #fff;
			opacity: 0;
			transition: opacity 0.2s;
			.slick-current &{
				opacity: 1;
			}
		}
	}
	&__corners-left{
		&:before,
		&:after{
			@extend %corners-style;
			left: 0;
		}
		&:before{
			top: 0;
			border-width: 3px 0 0 3px;
		}
		&:after{
			bottom: 0;
			border-width: 0 0 3px 3px;
		}
	}
	&__corners-right{
		&:before,
		&:after{
			@extend %corners-style;
			right: 0;
		}
		&:before{
			top: 0;
			border-width: 3px 3px 0 0;
		}
		&:after{
			bottom: 0;
			border-width: 0 3px 3px 0;
		}
	}
	&__info{
		text-align: center;
	}
	&__name{
		font-size: 20px;
		font-weight: 500;
		@include media-breakpoint-up(md) {
			font-size: 24px;
		}
	}
	&__post{
		font-weight: 500;
	}
	&__contacts{
		display: inline-block;
		margin-left: 0.6em;
		font-size: 20px;
		color: $red;
		transition: all 0.1s;
		&:hover{
			transform: scale(1.3);
		}
	}

	.slick{
		&-list{
			margin: 0 (-$gw/2);
			@include media-breakpoint-up(md) {
				margin: 0 -30px;
			}
			@include media-breakpoint-up(lg) {
				margin: 0 (-$gw/2);
			}
		}
		&-slide{
			padding: 0 ($gw/2);
			@include media-breakpoint-up(md) {
				padding: 0 30px;
			}
			@include media-breakpoint-up(lg) {
				padding: 0 ($gw/2);
			}
		}
		&-arrow{
			@extend %link-clicker;
			position: absolute;
			top: 100%;
			margin-top: 50px;
			font-size: 0;
			background: none;
			border: 3px solid;
			height: 48px;
			width: 48px;
			color: #fff;
			cursor: pointer;
			transition: color 0.2s;
			&:before{
				font-size: 18px;
				@extend .fa;
			}
			&:hover{
				color: $red;
			}
		}
		&-next{
			left: 50%;
			margin-left: 10px;
			&:before{
				@extend .fa-long-arrow-right;
			}
		}
		&-prev{
			right: 50%;
			margin-right: 10px;
			@extend .fa-long-arrow-left;
		}
	}
}