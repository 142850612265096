.slick{
	&-dots{
		li{
			list-style: none;
			button{
				padding: 6px;
				background: none;
				border: none;
				font-size: 0;
				cursor: pointer;
				&:before{
					content: '';
					display: block;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: #fff;
					transition: all 0.2s;
				}
			}
		}
		.slick-active{
			button{
				&:before{
					background: $red;
				}
			}
		}
	}
}

.slick-arrows-square {
	.slick{
		&-arrow{
			@extend %link-clicker;
			font-size: 0;
			background: none;
			border: 3px solid;
			height: 48px;
			width: 48px;
			color: #fff;
			cursor: pointer;
			transition: color 0.2s;
			&:before{
				font-size: 18px;
				@extend .fa;
			}
			// &:hover{
			// 	color: $red;
			// }
		}
		&-next{
			@extend .fa-long-arrow-right;
		}
		&-prev{
			@extend .fa-long-arrow-left;
		}
	}
}
.map-info{
	color: $color-text;
}

.datepickers-container {
	z-index: 1041;
}

.datepicker {
	color: #fff;
	background-color: $color-bg;
	border-color: $color-bg;
	.datepicker--pointer {
		background: $color-bg;
		border-color: $color-bg;
	}
}

.datepicker--nav {
	border-color: $color-bg-l;
}

.datepicker--day-name {
	color: $red;
}

.datepicker--cell.-disabled-,
.datepicker--cell.-current- {
	color: $gray;
	opacity: 0.5;
}

.datepicker--cell.-focus-,
.datepicker--nav-action:hover, .datepicker--nav-title:hover {
	background: lighten($red, 10%);
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-focus-, {
	background: $red;
}

.datepicker--cell.-focus-.-disabled- {
	background: transparent;
}

.r-tabs-anchor {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding: 30px;
	width: 100%;
	font-family: $font-2;
	font-size: 24px;
	line-height: 33px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	background-color: $color-bg;
	transition: 0.3s;
	&::before {
		content: "";
		position: absolute;
		left: 23px;
		bottom: -7px;
		z-index: 1;
		border: 7px solid $red;
		border-top-color: transparent;
		border-right-color: transparent;
		transform: rotate(-45deg);
		opacity: 0;
		visibility: hidden;
		transition: 0.3s;
	}
	&:hover {
		color: #fff;
		text-decoration: none;
		background-color: lighten($red, 3%);
	}
	@include media-breakpoint-up(md) {
		padding: 20px;
		&::before {
			left: 50%;
			transform: translateX(-50%) rotate(-45deg);
		}
	}
}

.r-tabs-state-active {
	.r-tabs-anchor {
		background-color: $red;
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
	padding: 0;
}

.r-tabs .r-tabs-accordion-title {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.r-tabs .r-tabs-nav {
	@include media-breakpoint-up(md) {
		display: flex;
	}
}