.advantages{
	padding: 65px 0 58px;
	background: $color-dimmed;
	color: $color-text;
	&--bg-dark{
		background: $color-bg-l;
		color: #fff;
	}
	&__row{
		margin-top: -30px;
	}
	&__col{
		margin-top: 30px;
	}
	&__item{
		display: flex;
		align-items: center;
		max-width: 280px;
		margin: 0 auto;
		@include media-breakpoint-up(md) {
			display: block;
			text-align: center;
			max-width: none;
		}
		&-img{
			height: 38px;
			transition: all 0.2s;
			margin-right: 25px;
			width: 60px;
			text-align: center;
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: center;
				margin-right: 0;
				width: auto;
			}
			&--big{
				height: 57px;
			}
			.advantages__item:hover &{
				transform: translateY(-20%);
			}
			img{
				display: inline-block;
				align-self: flex-end;
			}
		}
		&-text{
			font-weight: 500;
			p{
				@include media-breakpoint-up(md) {
					margin-top: 6px;
				}
			}
		}
	}
	&__text-lg{
		font-weight: 500;
		font-size: 30px;
		@include media-breakpoint-up(md) {
			font-size: 35px;
		}
	}
	&__text-md{
		font-size: 24px;
		font-weight: 500;
	}

	&--event {
		padding: 40px 0;
		@include media-breakpoint-up(md) {
			padding: 60px 0;
		}
		.advantages {
			&__row {
				margin: 0;
			}
			&__col {
				margin-top: 0;
				@include media-breakpoint-up(md) {
					display: flex;
					align-items: center;
					border-top: 1px solid $gray;
					&:nth-child(1),
					&:nth-child(2) {
						border-top: none;
					}
					&:nth-child(2n-1) {
						border-right: 1px solid $gray;
					}
				}
				@include media-breakpoint-up(xl) {
					border-right: 1px solid $gray;
					&:nth-child(4n) {
						border-right: 0;
					}
					&:nth-child(3),
					&:nth-child(4) {
						border-top: none;
					}
				}
			}
			&__item {
				padding-top: 15px;
				padding-bottom: 15px;
				height: 100%;
				&-img {
					flex-shrink: 0;
					width: 40px;
				}
				@include media-breakpoint-up(md) {
					padding-right: 25px;
					padding-left: 25px;
					&-img {
						margin-bottom: 28px;
						width: auto;
					}
				}
			}
		}
	}
}