.hall {
	position: relative;
	margin: 0 (-$gw);
	@include media-breakpoint-up(md) {
		margin: 0;
	}
	@include media-breakpoint-up(lg) {
		margin: -60px 0 0;
	}
	&__slider{
		padding-bottom: 70px;
		@include media-breakpoint-up(md) {
			padding-bottom: 0;
		}
	}
	&__item{
		@extend %clearfix;
		.slick-initialized &{
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(lg) {
				flex-direction: row;
			}
		}
	}
	@at-root{
		%hall-img-width{
			width: 100%;
			@include media-breakpoint-up(lg) {
				width: 55%;
			}
		}
	}
	&__img{
		@extend %hall-img-width;
		height: 370px;
		align-self: center;
		order: 2;
		@include media-breakpoint-up(md) {
			order: 0;
			height: 325px;
			padding-right: 100px;
		}
		@include media-breakpoint-up(lg) {
			padding: 60px 0;
			height: 495px;
		}
	}
	&__img-wrap{
		position: relative;
		height: 100%;
		img {
			height: 100%;
			width: 100%;
			display: block;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0; left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#27233A, 0.22);
		}
	}
	&__content{
		order: 1;
		background: $color-dimmed;
		color: $color-text;
		padding: 33px $gw;
		@include media-breakpoint-up(md) {
			order: 0;
			margin-left: 130px;
			padding: 38px 50px 50px;
		}
		@include media-breakpoint-up(lg) {
			padding: 45px;
			width: 45%;
			margin: 0;
		}
	}
	&__title{
		margin-top: 0;
		&:after{
			margin: 30px 0 40px;
		}
	}
	&__tags{
		margin-top: 12px;
		margin-left: -12px;
	}
	&__tag{
		display: inline-block;
		font-style: italic;
		font-weight: 500;
		color: $red;
		margin: 18px 0 0 12px;
		i{
			color: $color-text;
			margin-right: 0.4em;
		}
	}

	.slick{
		&-dots{
			@extend %hall-img-width;
			position: absolute;
			left: 0;
			width: auto;
			bottom: 7px;
			text-align: right;
			padding: 0 ($gw - 6px) 0 100px;
			@include media-breakpoint-up(md) {
				left: auto;
				bottom: auto;
				right: -6px;
				top: 240px;
				padding: 0;
			}
			@include media-breakpoint-up(lg) {
				padding: 0 30px 0 100px;
				right: auto;
				top: auto;
				left: 0;
				bottom: 7px;
			}
			li{
				display: inline-block;
			}
		}
		&-arrow{
			@extend %link-clicker;
			position: absolute;
			z-index: 1;
			bottom: 7px;
			left: 78px;

			background: none;
			padding: 0;
			border: none;
			color: inherit;
			font-size: 0;
			cursor: pointer;
			@include media-breakpoint-up(md) {
				left: 65px;
			}
			&:before{
				@extend .fa;
				font-size: 18px;
			}
		}
		&-prev{
			display: none;
		}
		&-next{
			@extend.fa-long-arrow-right;
		}
		&-paging{
			position: absolute;
			bottom: 0;
			left: $gw;
			@include media-breakpoint-up(md) {
				left: 0;
			}
		}
	}
}

.tarifs {
	background: $color-bg;
	&__list{
		margin-bottom: 60px;
		margin-top: -27px;
	}
	&__item{
		list-style: none;
		margin-top: 27px;
	}
}

.tarif {
	position: relative;
	bottom: 0;
	transition: all 0.2s;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
	@include media-breakpoint-up(lg) {
		flex-direction: column;
	}
	&:hover{
		bottom: 5px;
	}
	&__header{
		text-align: center;
		padding: 50px 10px 45px;
		background: $color-bg-l;
		@include media-breakpoint-up(md) {
			width: 170px;
			flex-shrink: 0;
		}
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		.tarifs__item:nth-child(2) &{
			background: $red;
		}
	}
	&__icon{
		margin-bottom: 10px;
		svg{
			display: inline-block;
			fill: #fff;
			width: 55px;
			height: 55px;
		}
	}
	&__title{
		font-size: 24px;
		font-family: $font-2;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	&__descr{
		font-weight: 500;
	}
	&__body{
		border: solid #fff;
		border-width: 0 3px 3px;
		padding: 40px 20px;
		@include media-breakpoint-up(md) {
			flex-grow: 1;
			border-width: 3px 3px 3px 0;
			padding: 30px;
		}
		@include media-breakpoint-up(lg) {
			flex-grow: 0;
			border-width: 0 3px 3px;
			padding: 40px 20px;
		}
		@include media-breakpoint-up(xl) {
			padding: 40px 70px;
		}
		ul{
			@include media-breakpoint-up(md) {
				display: flex;
				flex-flow: row wrap;
			}
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
		li{
			@include media-breakpoint-up(md) {
				width: 50%;
			}
			@include media-breakpoint-up(lg) {
				width: auto;
			}
		}
	}
	&__price{
		text-align: center;
		font-size: 24px;
		font-weight: 500;
		color: $red;
		margin-bottom: 30px;
	}

	&--event {
		height: 100%;
		color: $color-text;
		background-color: #fff;
		.tarif {
			&__header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #fff;
				@include media-breakpoint-up(md) {
					width: 210px;
				}
				@include media-breakpoint-up(lg) {
					width: auto;
				}
			}

			&__body {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				a.btn {
					margin-top: auto;
					color: #fff;
				}
				ul {
					margin-bottom: 40px;
				}
				@include media-breakpoint-up(md) {
					flex-direction: row;
					align-items: flex-end;
					ul {
						margin-bottom: 0;
						& > li {
							width: 100%;
						}
					}
					.text-center {
						flex-shrink: 0;
						.tarif__price {
							margin-bottom: 10px;
						}
					}
				}

				@include media-breakpoint-up(lg) {
					flex-direction: column;
					align-items: center;
					ul {
						margin-bottom: 40px;
					}
				}
			}
		}
	}
}

.equipment{
	background: $color-bg;
	&__inner{
		@include media-breakpoint-up(lg) {
			display: flex;
			margin-top: -130px;
		}
	}
	&__slider{
		position: relative;
		z-index: 1;
		padding-top: 80px;
		@include media-breakpoint-up(md) {
			padding-top: 0;
		}
		@include media-breakpoint-up(lg) {
			left: 23px;
			padding-bottom: 80px;
			text-align: right;
			margin-top: 125px;
			width: cp(460, 1170);
		}
		.slick{
			&-arrow{
				position: absolute;
				top: 0;
				@include media-breakpoint-up(md) {
					top: -130px;
				}
				@include media-breakpoint-up(lg) {
					top: auto;
					bottom: 0;
				}
			}
			&-prev{
				right: 50%;
				margin-right: 10px;
				@include media-breakpoint-up(md) {
					right: 70px;
					margin: 0;
				}
				@include media-breakpoint-up(lg) {
					right: 165px;
				}
			}
			&-next{
				left: 50%;
				margin-left: 10px;
				@include media-breakpoint-up(md) {
					left: auto;
					right: 0;
					margin: 0;
				}
				@include media-breakpoint-up(lg) {
					right: 99px;
				}
			}
		}
	}
	&__item{
		list-style: none;
		margin-bottom: 10px;
		&-link{
			display: inline-flex;
			align-items: center;
			position: relative;
			font-weight: 500;
			text-transform: uppercase;
			&:hover{
				text-decoration: none;
			}
		}
		&-text{
			order: 2;
			@include media-breakpoint-up(lg) {
				order: 0;
			}
		}
		&-icon{
			display: block;
			position: relative;
			flex-shrink: 0;
			order: 1;
			width: 48px;
			height: 48px;
			background: $red;
			margin-right: 20px;
			@include media-breakpoint-up(lg) {
				margin: 0 0 0 50px;
				order: 0;
			}
			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				width: 18px;
				height: 2px;
				top: 0; bottom: 0; left: 0; right: 0;
				margin: auto;
				background: #fff;
				transition: all 0.2s;
				.b-price__header:hover &{
					background: $red;
				}
			}
			&:after{
				transform: rotate(90deg);
			}
			.equipment__item.active &{
				&:after{
					transform: rotate(0);
				}
			}
		}
	}
	&__content{
		position: relative;
		margin: 0 (-$gw);
		@include media-breakpoint-up(md) {
			margin: 0;
		}
		@include media-breakpoint-up(lg) {
			width: cp(1170-460, 1170);
			padding-top: 60px;
			padding-right: 60px;
		}
		&:before{
			@include media-breakpoint-up(lg) {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 60px;
				border: 3px solid #fff;
				width: 325px;
			}
		}
		&-inner{
			position: relative;
			height: 1px;
			min-height: 390px;
			@include media-breakpoint-up(md) {
				min-height: 290px;
			}
			@include media-breakpoint-up(lg) {
				height: 100%;
				min-height: auto;
			}
		}
	}
	&__img-wrap{
		position: absolute;
		width: 100%;
		height: 100%;
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			background: rgba(#27233A, 0.22);
		}
		img{
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	&__text{
		position: relative;
		display: none;
		height: 100%;
		background: $color-dimmed;
		color: $color-text;
		padding: 30px $gw 20px;
		@include media-breakpoint-up(md) {
			padding: 45px 35px 50px;
		}
		@include media-breakpoint-up(lg) {
			padding: 45px 35px 50px 60px;
		}
		&-title{
			font-size: 24px;
			text-transform: uppercase;
			font-family: $font-2;
			margin-bottom: 1em;
		}
	}
}