.contacts{
	position: relative;
	&__inner{
		border: 3px solid #fff;
		padding: 30px 17px;
		@include media-breakpoint-up(md) {
			min-height: 375px;
			margin: 0 0 45px 115px;
			padding: 185px 35px 40px 35px;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
		@include media-breakpoint-up(xl) {
			margin: 0 45px 0 115px;
			padding: 185px 45px 40px 45px;
		}
		.contacts--pos-contacts &{
			@include media-breakpoint-up(md) {
				padding-top: 205px;
			}
		}
	}
	&__title{
		position: relative;
		margin: 0 0 30px -20px;
		background: $color-bg-l;
		padding: 10px 0 35px 3px;
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 29px;
			left: 0;
			margin: 0;
			padding: 10px 0 35px;
		}
		.contacts--pos-contacts &{
			padding-bottom: 55px;
		}
		&:after{
			position: absolute;
			bottom: 5px;
			left: 3px;
			margin: 0;
			@include media-breakpoint-up(md) {
				bottom: 0;
				left: 0;
			}
		}
	}
	&__text{
		width: 100%;
	}
	&__footer{
		margin-top: 30px;
		@include media-breakpoint-up(lg) {
			margin-top: 44px;
		}
	}
	&__btn-place{
		text-align: center;
		margin: 30px 0;
		@include media-breakpoint-up(md) {
			position: absolute;
			left: 0;
			bottom: 0;
			margin: 0;
		}
	}
	&__btn{
		@include media-breakpoint-up(md) {
			transform: rotate(-90deg) translateY(100%);
			transform-origin: bottom left;
			padding-left: 20px;
			padding-right: 20px;
		}
		&:active{
			@include media-breakpoint-up(md) {
				transform: rotate(-90deg) translateY(100%);
			}
		}
	}
}


.map-route{
	position: relative;
	z-index: 1;
	padding: 18px 20px;
	background: $color-bg;
	@include media-breakpoint-up(md) {
		padding: 22px 30px;
	}
	&__title{
		text-transform: uppercase;
		font-family: $font-2;
		font-size: 20px;
		text-align: center;
		@include media-breakpoint-up(md) {
			font-size: 24px;
			text-align: left;
		}
	}
	&__types{
		margin-left: -10px;
		margin-right: -7px;
		margin-top: 10px;
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: left;
			margin-top: 0;
		}
	}
	&__type{
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		svg{
			display: block;
			width: 2em;
			height: 2em;
			fill: #fff;
			transition: all 0.2s;
		}
		&:hover{
			svg{
				fill: lighten($red, 20%);
			}
		}
		&.active{
			svg{
				fill: $red;
			}
		}
	}
	&__form{
		position: absolute;
		top: 100%;
		margin-top: 20px;
		left: 15px;
		right: 15px;
		@include media-breakpoint-up(md) {
			left: 30px;
			right: 30px;
		}
	}
	&__input-box{
		position: relative;
		padding-right: 64px;
	}
	&__input{
		height: 48px;
		width: 100%;
		padding: 0 10px;
		border: 3px solid #fff;
		color: #fff;
		background: #656565;
		@include placeholderStyle {
			color: #fff;
		}
	}
	&__btn{
		position: absolute;
		top: 0;
		right: 0;
		height: 48px;
		width: 48px;
		min-width: 0;
		padding: 0;
		font-size: 24px;
	}
}
.map{
	position: relative;
	padding-right: 15px;
	@include media-breakpoint-up(md) {
		padding-right: 35px;
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: 30px;
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	&:before{
		content: '';
		display: block;
		position: absolute;
		right: 0;
		width: cp(340,525);
		background: $red;
		top: 25px;
		bottom: -15px;
		@include media-breakpoint-up(md) {
			top: auto;
			height: 315px;
			bottom: 0;
		}
	}
	&__layout{
		position: relative;
		background: gray;
		height: 260px;
		@include media-breakpoint-up(md) {
			height: 375px;
		}
		@include media-breakpoint-up(lg) {
			height: auto;
			flex-grow: 1;
		}
	}
}