body{
	.not-loaded &{
		overflow: hidden;
	}
}

.preloader{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: $zindex-popover;
	visibility: hidden;
	opacity: 0;
	transition: all 0s 0.6s;
	.not-loaded &{
		transition: all 0s 0s;
		visibility: visible;
		opacity: 1;
	}
	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 0;
		background-color: $color-bg;
		transition: all 0.4s 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
		.not-loaded &{
			width: 50%;
			transition: all 0.5s 0s ease;
		}
	}
	&:before{
		left: 0;
	}
	&:after{
		right: 0;
	}
	// &-logo{
	// 	width:200px;
	// 	height:200px;
	// 	position:absolute;
	// 	z-index: 1;
	// 	left:50%;
	// 	top:50%;
	// 	background-image:url('../images/svg/logo.svg');
	// 	background-repeat:no-repeat;
	// 	background-position:center;
	// 	background-size: 135px 95px;
	// 	margin:-100px 0 0 -100px;
	// 	opacity: 0;
	// 	transition: all 0.2s;
	// 	.not-loaded &{
	// 		transition: all 0.2s 0.2s;
	// 		opacity: 1;
	// 	}
	// }
	&__content{
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 18px;
		text-transform: uppercase;
		font-family: sans-serif;
		color: lighten($color-bg-l, 10%);
		opacity: 0;
		transition: opacity 0.2s;
		.not-loaded &{
			transition: opacity 0.2s 0.2s;
			opacity: 1;
		}

		img{
			display: block;
			opacity: 0.1;
			margin-bottom: 10px;
		}

		svg{
		}
	}
	&__svg{
		width: 135px;
		// opacity: 0.5;
	}
	&__border{
		transition: all 60s linear;
	}
}
