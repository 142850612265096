.gallery{
	&--offset-top{
		margin-top: 55px;
	}
	&--bg-light{
		background: $color-dimmed;
		color: $color-text;
	}
	&__title{
		margin-top: 0;
		&:after{
			margin-top: 30px;
			margin-bottom: 40px;
		}
	}
	&__tabs{
		text-align: center;
		margin: -5px -14px 25px;
	}
	&__tab{
		margin: 5px 14px 0;
		display: inline-block;
		list-style: none;
		&.active{
			color: $red;
		}
		&-link{
			@extend %link-clicker;
			display: block;
			font-weight: 500;
			&:hover{
				text-decoration: none;
			}
		}
	}
	&__wrap{
		margin: 0 -3px 35px;
		@include media-breakpoint-up(md) {
			margin: 0 (-$gw/2) 45px;
		}
	}
	&__list{}
	&__sizer,
	&__item{
		list-style: none;
		width: cp(1,2);
		@include media-breakpoint-up(lg) {
			width: cp(1,3);
		}
	}
	&__item{
		float: left;
		overflow: hidden;
		padding: 3px;
		@include media-breakpoint-up(md) {
			padding: ($gw/2);
		}
		&--1x1{
			height: 30vw;
			@include media-breakpoint-up(xl) {
				height: auto;
			}
		}
		&--1x2{
			height: 60vw;
			@include media-breakpoint-up(xl) {
				height: auto;
			}
		}
	}
}

.item-gallery{
	position: relative;
	display: block;
	height: 100%;
	&:after{
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 3px solid #fff;
			opacity: 0;
			transition: all 0.2s;
			background: url($path+'images/svg/eye.svg') center no-repeat;
			background-size: 48px 28px;
		}
	}
	&--video::after {
		background-image: url($path+'images/svg/play.svg');
		background-size: 58px 58px;
	}
	&:hover{
		text-decoration: none;
		color: inherit;
		&:after{
			opacity: 1;
			top: 30px;
			left: 30px;
			right: 30px;
			bottom: 30px;
		}
	}
	&__img-wrap{
		display: block;
		overflow: hidden;
		height: 100%;
		.gallery__item--1x1 &{
			&:before{
				padding-bottom: 0;
				@include media-breakpoint-up(xl) {
					padding-bottom: cp(251, 377);
				}
			}
		}
		.gallery__item--1x2 &{
			&:before{
				padding-bottom: 0;
				@include media-breakpoint-up(xl) {
					padding-bottom: cp(523, 377);
				}
			}
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#27233A, 0.22);
			opacity: 1;
			transition: all 0.2s;
			.item-gallery:hover &{
				opacity: 0;
			}
		}
	}
	&__img{
		transition: all 1.5s;
		.item-gallery:hover &{
			transform: scale(1.1);
		}
	}
	&__caption{
		position: absolute;
		bottom: 40px;
		left: 60px;
		right: 60px;

		max-height: 3em;
		text-align: center;
		color: #fff;

		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s;
		.item-gallery:hover &{
			opacity: 1;
			visibility: visible;
			bottom: 55px;
		}
	}
}