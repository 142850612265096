$extralight:	100;
$thin:			200;
$light:			300;
$regular:		400;
$normal:		$regular;
$medium:		500;
$semibold:		600;
$bold:			700;
$extrabold:		800;
$black:			900;

@mixin font-urls($name, $dir: '', $path: $font-path) {
	src: url($path + $dir + $name +'.eot');
	src:
	url($path + $dir + $name +'.woff2') format('woff2')
	,url($path + $dir + $name +'.woff') format('woff')
	,url($path + $dir + $name +'.ttf') format('ttf')
	,url($path + $dir + $name +'.svg#'+ $name) format('svg')
	,url($path + $dir + $name +'.eot?#iefix') format('embedded-opentype')
	;
}

@font-face {
	@include font-urls('MuseoSansCyrl-300');
	font-family: $font-1;
	font-weight: $normal;
	font-style: normal;
}

@font-face {
	@include font-urls('MuseoSansCyrl-300Italic');
	font-family: $font-1;
	font-weight: $normal;
	font-style: italic;
}

@font-face {
	@include font-urls('MuseoSansCyrl-500');
	font-family: $font-1;
	font-weight: $medium;
	font-style: normal;
}

@font-face {
	@include font-urls('MuseoSansCyrl-500Italic');
	font-family: $font-1;
	font-weight: $medium;
	font-style: italic;
}

@font-face {
	@include font-urls('MuseoSansCyrl-700');
	font-family: $font-1;
	font-weight: $bold;
	font-style: normal;
}